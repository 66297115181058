import React from "react";
import {
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  Link,
  Grid,
} from "@material-ui/core";

import {
  sfdFactoryAddr,
  sfdTokenAddr
} from "../constants/consts";

import { Icon } from "@iconify/react";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 20,
        paddingBottom: 0,
      },
    },

    footer_tst1: {
      marginTop: "calc(1%)",
      bottom: 0,
      
    },
    footer_tst2: {
      minHeight: '100%',

      /* sticky footer */
      boxSizing: 'border-box',
      marginBottom: '-100px',
      paddingBottom: '100px',
    },

    footerbar: {
      background: "transparent",
      boxShadow: "none",
    },

    footerRight: {
      marginLeft: "auto",
    },
    Links: {
      marginLeft: theme.spacing(5),
      fontSize: "1.3em",
    },
  };
});

function SfdFooter() {
  const classes = useStyles();

  return (
  <footer className={classes.root} >
      <AppBar position="static" color="inherit" className={classes.footerbar}>
        <Grid>
          <Toolbar>
            <Grid item>
              <Typography>
                <Link color="inherit" href="/about" className={classes.Links}>
                  {'About'}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <Link color="inherit" href="/faq" className={classes.Links}>
                  {"Faq"}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <Link color="inherit" href="/listing" className={classes.Links} >
                  {"Coin Listing"}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <Link color="inherit" href={`https://bscscan.com/token/${sfdTokenAddr}`} className={classes.Links} target="_blank">
                  {"wSFD Contract"}
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <Link color="inherit" href={`https://bscscan.com/address/${sfdFactoryAddr}`} className={classes.Links} target="_blank">
                  {"Bridge Contract"}
                </Link>
              </Typography>
            </Grid>

            <Grid item className={classes.footerRight}>
              <IconButton aria-label="Discord" color="default" onClick={() => window.open("https://discord.safedeal.trade/", "_blank")}>
                <Icon icon="mdi-discord" width="32" height="32" />
              </IconButton>
              <IconButton aria-label="Telegram" color="default" onClick={() => window.open("https://t.me/safedealp2p", "_blank")}>
                <Icon icon="fa-brands:telegram-plane" width="32" height="32" />
              </IconButton>
              <IconButton aria-label="Twitter" color="default" onClick={() => window.open("https://twitter.com/SafeDealP2P", "_blank")}>
                <Icon icon="mdi-twitter" width="32" height="32" />
              </IconButton>
              <IconButton aria-label="Reddit" color="default" onClick={() => window.open("https://www.reddit.com/r/SafeDealP2P", "_blank")}>
                <Icon icon="mdi-reddit" width="32" height="32" />
              </IconButton>
              <IconButton aria-label="Medium" color="default" onClick={() => window.open("https://medium.com/@SafeDealP2P", "_blank")}>
                <Icon icon="bx:bxl-medium" width="32" height="32" />
              </IconButton>
            </Grid>
          </Toolbar>
        </Grid>
      </AppBar>
    </footer>
  );
}

export default SfdFooter;
