import React from 'react';
import { SnackbarProvider } from 'notistack';
import './App.css';
import Web3ReactManager from './components/Web3ReactManager';
import MainPage from './components/MainPage';

import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
import { Web3ReactProvider } from '@web3-react/core'
import { CssBaseline } from '@material-ui/core';

function getLibrary(
  provider: ExternalProvider | JsonRpcFetchFunc
): Web3Provider {
  const rpc = new Web3Provider(provider, "any");
  rpc.pollingInterval = 15000;
  return rpc;
}

function App() {
  return (
    <SnackbarProvider
    maxSnack={4}
    autoHideDuration={4000}
    resumeHideDuration={3000}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}>
      <Web3ReactProvider getLibrary={getLibrary}>
      <CssBaseline />
      <Web3ReactManager>
      
        <MainPage />
      </Web3ReactManager>
    </Web3ReactProvider>
  </SnackbarProvider>

  );
}

export default App;
