import { SvgIcon, SvgIconProps } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import React, { FunctionComponent } from "react";


import { ReactComponent as MetamaskFox } from "./../../assets/metamask-fox.svg";
import { ReactComponent as BinanceChain } from "./../../assets/binancechain.svg";
import { ReactComponent as Sfd } from "./../../assets/sfd.svg";
import { ReactComponent as WSfd } from "./../../assets/wsfd.svg";



export type CustomSvgIconComponent =
  | FunctionComponent<SvgIconProps>
  | SvgIconComponent;
  
export const MetamaskFoxIcon: CustomSvgIconComponent = (props) => (
    <SvgIcon component={MetamaskFox} viewBox="0 0 34 32" {...props} />
  );
  
  export const BinanceChainIcon: CustomSvgIconComponent = (props) => {
    return <SvgIcon component={BinanceChain} viewBox="0 0 34 40" {...props} />;
  };

  export const SfdIcon: CustomSvgIconComponent = (props) => {
    return <SvgIcon component={Sfd} viewBox="0 0 34 40" {...props} />;
  };

  export const WSfdIcon: CustomSvgIconComponent = (props) => {
    return <SvgIcon component={WSfd} viewBox="0 0 34 40" {...props} />;
  };