//step2 of mint
//TODO try to add border animation to Instruction box. Problem with animations in flexBox containers!!!
import { useEffect, useState } from "react";
import axios from "axios";
import { Tooltip, Grid, Box, Typography, Paper, Link } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { InfoButton, CopyButton } from "./helpers/HelpButtons";
import IconTooltip from "./helpers/IconTooltip";
import { SfdLinearProgress, SfdCircularProgress } from "./helpers/ProgressBars";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { fromWei } from "web3-utils";
import useInterval from "../hooks/useInterval";
import { bridgeServURL } from "../constants/consts";
import { shortenString } from "../utils/format";

import { BinanceChainIcon, MetamaskFoxIcon, SfdIcon } from "./icons/BridgeIcons";
import { useWeb3React } from "@web3-react/core";
import { bscConnector } from "../constants/connectors";

import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  papperBack: {
    marginTop: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    flexGrow: 1,
    position: "relative",
    minWidth: "400px",
    maxWidth: "400px",
  },
  avatar: {
    margin: theme.spacing(1),
    fontSize: 60,
  },

  mintStatusBox: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  mintStatusText: {
    textAlignLast: "center",
  },

  smallText: {
    fontSize: "0.7em",
  },

  orderBox: {
    marginTop: theme.spacing(2),
  },

  paperInstructions: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  instructionsText: {
    color: "red",
    fontWeight: "bold",
  },
  progressText: {
    color: "green",
    fontWeight: "bold",
  },
  progressBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  progressBoxLine: {
    flexGrow: 1,
  },
}));

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Minting() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const query = useQuery();
  const requestHash = query.get("hash");
  const [notFound, setNotFound] = useState(false);

  const [err404Count, setErr404Count] = useState(0);
  const [sfdDepositAddr, setSfdDepositAddr] = useState<string>("");
  const [bscAddr, setBscAddr] = useState<string>("");
  const [sfdAmount, setSfdAmount] = useState(0);
  const [sfdFilledAmount, setFilledAmount] = useState(0);
  const [tokenAmount, setTokenAmount] = useState<string>("");
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [orderSearch, setOrderSearch] = useState(true);
  const [sfdTransactions, setSfdTransactions] = useState([]);

  const sfdTxExplorer = "https://explorer.safedeal.trade/tx/";

  const checkOrder = async () => {
    if (!requestHash || orderStatus === "Minted") return;

    if (err404Count > 3) {
      setNotFound(true);
      setOrderSearch(false);
      return;
    }

    try {
      let servResp = await axios.get(
        `${bridgeServURL}/getOrderByHash?hash=${requestHash}`
      );
      let order = servResp?.data?.order;
      if (!order) return;

      setOrderSearch(false);
      setOrderStatus(order?.status); // New Order, Waiting for confirmations, Filled, Partially filled, Minted
      setSfdDepositAddr(order?.addressIN);
      setBscAddr(order?.addressOUT);
      setSfdAmount(order?.amount);
      setTokenAmount(order?.amountTokens);
      setFilledAmount(order?.filledAmount);
      setSfdTransactions(order?.txs);

      console.log(order);
    } catch (error) {
      if (error?.response?.status === 404) {
        setErr404Count(err404Count + 1);
        return;
      }
      if (error?.response?.status === 400) {
        setErr404Count(5);
        return;
      }

      enqueueSnackbar(`Some Error`, {
        variant: "error",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (requestHash?.length !== 66) {
      history.push("/mint");
      return;
    }
    checkOrder();
  }, []);

  useInterval(checkOrder, 10000);

  function getMintStatusHead() {
    switch (orderStatus) {
      case "New Order":
        return "Waiting for deposit...";
      case "Waiting for confirmations":
        return "Waiting for SFD tx confirmation...";
      case "Partially filled":
        return "Waiting for full deposit...";
      case "Filled":
        return "Minting tokens...";
      case "Minted":
        return "Tokens minted successfully";

      default:
        return "Searching for Order...";
    }
  }

  const walConnector = useWeb3React();
  let isConnected = false;
  if (walConnector.connector === undefined) isConnected = false;
  else isConnected = true;

  if (orderSearch) {
    //searching
    return (
      <Grid container direction="column" alignItems="center" justify="center">
        <Paper
          className={classes.papperBack}
          elevation={5}
          style={{ minHeight: "300px" }}
        >
          <Box marginTop="40px">
            <Typography variant="h4">Searching for order...</Typography>
          </Box>
          <Box marginTop="20px">
            <SfdCircularProgress size={100} />
          </Box>
          <Box marginTop="60px">
            <Typography>please wait</Typography>
          </Box>
        </Paper>
      </Grid>
    );
  } else if (notFound) {
    //order not found
    return (
      <Grid container direction="column" alignItems="center" justify="center">
        <Paper
          className={classes.papperBack}
          elevation={5}
          style={{ minHeight: "300px" }}
        >
          <Box marginTop="40px">
            <Typography variant="h4">Order not found!</Typography>
          </Box>
          <Box marginTop="20px">
            <Icon
              icon="codicon:error"
              width="120"
              height="120"
              style={{ color: "#e60000" }}
            />
          </Box>
          <Box
            marginTop="60px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography>Server could not find find this order.</Typography>
            <Typography>
              Please check whether the order ID(hash) is correct.
            </Typography>
          </Box>
        </Paper>
      </Grid>
    );
  } else {
    //order proccessing
    return (
      <Grid container direction="column" alignItems="center" justify="center">
        <Paper className={classes.papperBack} elevation={5}>
          {/*-------------from-to text----------*/}
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Typography
                  className={classNames(classes.smallText, "noselect")}
                >
                  From
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center"></Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Typography
                  className={classNames(classes.smallText, "noselect")}
                >
                  To
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/*-------------2 icons----------*/}
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
              <div>
                  <SfdIcon style={{ fontSize: 60, margin: 10 }} />
                </div>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Icon
                  icon="dashicons:arrow-right-alt2"
                  width="44"
                  height="44"
                  style={{ fontSize: 60, margin: 10, color: "grey" }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <div>
                  <BinanceChainIcon style={{ fontSize: 60, margin: 10 }} />
                </div>
              </Box>
            </Grid>
          </Grid>
          {/*-------------chains-to text----------*/}
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Typography
                  className={classNames(classes.smallText, "noselect")}
                >
                  SFD Coin Blockchain
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center"></Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Typography
                  className={classNames(classes.smallText, "noselect")}
                >
                  Binance Smart Chain
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/*-------------order hash raw----------*/}

          <Grid container justify="space-between" className={classes.orderBox}>
            <Box display="flex" justifyContent="left">
              <Typography className={"noselect"}>{"Order id"}</Typography>
              <InfoButton helpText="Your unique exchange id in SFD Bridge" />
            </Box>
            <Box display="flex" justifyContent="right">
              <Tooltip title={` ${requestHash ? requestHash : ".."}`}>
                <Typography className={"noselect"}>
                  {` ${requestHash ? shortenString(requestHash, 15) : ".."}`}{" "}
                </Typography>
              </Tooltip>
              <CopyButton textToCopy={`${requestHash ? requestHash : " "}`} />
            </Box>
          </Grid>
          {/*-------------Destination addr raw----------*/}
          <Grid container justify="space-between">
            <Box display="flex" justifyContent="left">
              <Typography className={"noselect"}>{"Destination"}</Typography>
              <InfoButton helpText="Your BSC network address" />
            </Box>

            <Box display="flex" justifyContent="right">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {isConnected ? (
                  walConnector.connector === bscConnector ? (
                    <BinanceChainIcon style={{ fontSize: 18, margin: 1 }} />
                  ) : (
                    <MetamaskFoxIcon style={{ fontSize: 16, margin: 1 }} />
                  )
                ) : (
                  ""
                )}
              </Box>

              <Tooltip title={bscAddr}>
                <Typography className={"noselect"}>{`${shortenString(
                  bscAddr,
                  15
                )}`}</Typography>
              </Tooltip>
              <CopyButton textToCopy={bscAddr} />
            </Box>
          </Grid>
          {/*-------------wSFD recieve raw----------*/}
          <Grid container justify="space-between">
            <Box display="flex" justifyContent="left">
              <Typography className={"noselect"}>
                {"You will recieve"}
              </Typography>
              <InfoButton helpText="You will recieve this amount of wSFD" />
            </Box>

            <Box display="flex" justifyContent="center">
              <Typography className={"noselect"}>{`${fromWei(
                tokenAmount
              )} wSFD`}</Typography>
            </Box>
          </Grid>
          {/*-------------Instructions raw----------*/}
          <Grid container justify="space-between">
            <Grid item xs={12}>
              {orderStatus !== "Filled" && orderStatus !== "Minted" ? (
                <Paper
                  elevation={0}
                  variant="outlined"
                  className={classes.paperInstructions}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      className={classNames(
                        classes.instructionsText,
                        "noselect"
                      )}
                    >
                      {"Follow instructions"}
                    </Typography>

                    <Typography>
                      {"Send "}
                      <strong>{`${sfdAmount} SFD `}</strong>
                      {"coins to"}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <Typography>
                        <strong>{`${sfdDepositAddr}`}</strong>
                      </Typography>
                      <CopyButton textToCopy={sfdDepositAddr} />
                    </Box>
                  </Box>
                </Paper>
              ) : (
                ""
              )}

              {/*-------------Progress raw----------*/}
              <Paper
                elevation={0}
                variant="outlined"
                className={classes.paperInstructions}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={classNames(classes.progressText, "noselect")}
                  >
                    {notFound ? "Can't find order.." : getMintStatusHead()}
                  </Typography>
                  <Box className={classes.progressBox}>
                    {orderStatus !== "Filled" && orderStatus !== "Minted" ? (
                      <SfdCircularProgress size={40} />
                    ) : (
                      ""
                    )}
                    {orderStatus === "Filled" ? (
                      <Box display="flex" justifyContent="center">
                        <IconTooltip
                          title="You have deposited all SFD coins"
                          pos="left-start"
                          icon="ion-checkmark-done-circle"
                          size="48"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                    {orderStatus === "Minted" ? (
                      <Box display="flex" justifyContent="center">
                        <IconTooltip
                          title="You have deposited all SFD coins"
                          pos="left-start"
                          icon="ion-checkmark-done-circle"
                          size="48"
                        />
                        <IconTooltip
                          title="Your wSFD tokens successfully minted"
                          pos="right-start"
                          icon="healthicons:miner-worker"
                          size="48"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={4}>
                      <Box display="flex" justifyContent="left">
                        <Typography className={"noselect"}>
                          {"Received SFD"}
                        </Typography>
                        <InfoButton helpText="You already sent this SFD amount" />
                      </Box>
                    </Grid>

                    <Grid item xs={5}>
                      <SfdLinearProgress
                        value={
                          sfdAmount > 0 && sfdFilledAmount > 0
                            ? (sfdFilledAmount / sfdAmount) * 100
                            : 0
                        }
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="center">
                        <Typography className={"noselect"}>
                          <strong>{`${sfdFilledAmount}/${sfdAmount}`}</strong>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container justify="space-between">
                    <Box display="flex" justifyContent="left">
                      <Typography className={"noselect"}>
                        {"Deposit transactions"}
                      </Typography>
                      <InfoButton helpText="Transactions of your deposits in SFD blockchain" />
                    </Box>
                  </Grid>

                  {sfdTransactions.map((tx) => (
                    <Typography>
                      <Link href={sfdTxExplorer + tx}>
                        {shortenString(tx, 40)}
                      </Link>
                    </Typography>
                  ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

export default Minting;
