import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  Box,
  Typography,
  Paper,
  InputBase,
  ButtonBase,
} from "@material-ui/core";
import { toWei } from "web3-utils";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useWeb3React } from "@web3-react/core";

import useContract from "../hooks/useContract";
import { sfdFactoryAddr, bridgeServURL } from "../constants/consts";
import sfdFactoryABI from "../constants/abis/sfdFactory.json";
import { escapeRegExp, inputNumberRegex } from "../utils/format";
import { BinanceChainIcon, SfdIcon, WSfdIcon } from "./icons/BridgeIcons";
import { SfdCircularProgress } from "./helpers/ProgressBars";
import { Icon } from "@iconify/react";

const useStyles = makeStyles((theme) => ({
  papperBack: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    flexGrow: 1,
    position: "relative",
    maxWidth: "500px",
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  cont: {
    alignItems: "center",
  },

  boxBackground: {
    backgroundColor: "#F7F8FA",
    borderColor: "#f4f6f8",
    "&:hover": {
      borderColor: "#e5e8ea",
    },
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.1),
  },

  boxBackgroundSelector: {
    backgroundColor: "#F7F8FA",
    borderColor: "#f4f6f8",
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.1),
  },

  usdText: {
    fontSize: "0.9em",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  arrowBtn: {
    position: "absolute",
    margin: theme.spacing(-2.5),
    backgroundColor: "#F7F8FA",
    border: "0px",
    borderRadius: "15px",
    color: "#8b8e97",
    "& :hover": {
      color: "#afb2ba",
    },
  },
  mintBtn: {
    borderWidth: "1px",
    border: "solid",
    borderColor: "#1a90ff",
    borderRadius: "10px",
  },

  burnBtn: {
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: "#F7F8FA",
      color: "#1a90ff",
    },
  },

  selectorMargin: {
    margin: theme.spacing(0.2),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1a90ff",
    "&:hover": {
      backgroundColor: "#0d66ba",
    },
  },
}));

function MintForm() {
  const classes = useStyles();
  const [sfdAmount, setSfdAmount] = useState(0);
  const [wsfdAmount, setWSfdAmount] = useState(0);
  const [usdSum, setUsdSum] = useState(0);
  const { account, active } = useWeb3React();
  const sfdFactory = useContract(sfdFactoryAddr, sfdFactoryABI, true);
  const [loding, setLoding] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [sfdMultiplier, setSfdMultiplier] = useState(0.5);

  useEffect(() => {
    let isMounted = true;
    try {
      axios.get(`${bridgeServURL}/getMultiplier`).then(function (response) {
        let mul = response?.data?.sfdMultiplier;
        if (mul && isMounted)
          setSfdMultiplier(Number(mul));
      })
        .catch(function (error) {
          console.error(error);
        })
    } catch (e) {
      console.error(e);
    }
    return () => { isMounted = false };
  }, []);

  async function handleMint(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!active || !account) return;

    setLoding(true);
    let tx: any = null;
    let res: any = null;

    try {
      tx = await sfdFactory?.addMintRequest(toWei(String(wsfdAmount)));
    } catch (error) {
      console.log("addMintRequest Error:", error);
      enqueueSnackbar(
        `${error?.message
          ? error.message
          : "Can't use 'addMintRequest' method of smart contract 'sfdFactory'"
        }`,
        {
          variant: "error",
        }
      );
      setLoding(false);
      return;
    }

    try {
      res = await tx.wait();
    } catch (error) {
      console.log("tx.wait Error:", error);
      enqueueSnackbar(
        `${error?.message
          ? error.message
          : "Mint TX failed, some error in tx.wait"
        }`,
        {
          variant: "error",
        }
      );
      setLoding(false);
      return;
    }

    setLoding(false);
    if (res?.status !== 1) {
      enqueueSnackbar(`Mint TX failed: tx_status != 1`, {
        variant: "error",
      });
      console.log("Mint TX failed! tx_status != 1", res);
      return;
    }
    let mintEvent =
      res.events[0]?.event === "MintRequestAdd" ? res.events[0] : null;
    if (!mintEvent) {
      enqueueSnackbar(`Can't find mint event (MintRequestAdd) in transaction`, {
        variant: "error",
      });
      console.log("Cant find Event: 'MintRequestAdd'", res); // SHOW_ERROR
      return;
    }
    console.log(mintEvent.args.requestHash); // "requestHash" is the hashID of this mint ORDER
    const handleTx = () =>
      history.push("/minting?hash=" + mintEvent.args.requestHash);
    handleTx();
  }

  function calcAmount(amount: number) {
    setSfdAmount(amount);
    setWSfdAmount(amount / sfdMultiplier);
  }

  function handleSfdValueChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const inputStr = event.target.value;
    if (inputNumberRegex.test(escapeRegExp(inputStr))) {
      const nAmount = Number(inputStr);
      calcAmount(nAmount ? nAmount : 0);
      return;
    }
    event.target.value = sfdAmount > 0 ? String(sfdAmount) : "";
  }

  async function calcUsdSum() {
    let price: number = 0;
    if (sfdAmount <= 0)
      return setUsdSum(0);
    try {
      let servResp = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=safe-deal"
      );
      price = servResp?.data["safe-deal"]?.usd;
      setUsdSum(price * sfdAmount);
    } catch (error) { }
  }

  useEffect(() => {
    calcUsdSum();
  }, [sfdAmount]);

  function getUsdSum() {
    return `~$ ${(Math.round(usdSum * 100) / 100).toFixed(2)}`;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
   

      <Paper className={classes.papperBack} elevation={5}>
        {/*MINT BURN SELECTOR*/}
        <Grid container justify="flex-end">
          <Grid item xs={6}>
            <Box className={classes.boxBackgroundSelector}>
              <Grid container className={classes.selectorMargin}>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    fullWidth
                    disableRipple
                    className={classes.mintBtn}
                  >
                    MINT
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    fullWidth
                    disableRipple
                    className={classes.burnBtn}
                    component={Link}
                    to="/burn"
                  >
                    BURN
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <form className={classes.form} onSubmit={handleMint} autoComplete="off">
          {/*INPUT SFD FIELD  */}
          <Box className={classes.boxBackground}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                <Box display="flex" justifyContent="left" alignItems="center">
                  <SfdIcon style={{ fontSize: 33, margin: 10 }} />
                  <Typography variant="h5" className="noselect">
                    SFD
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  style={{
                    backgroundColor: "#F7F8FA",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <InputBase
                      inputProps={{
                        style: {
                          fontSize: 30,

                          textAlign: "right",
                          marginRight: "10px",
                        },
                      }}
                      placeholder="0.0"
                      onChange={(e) => handleSfdValueChange(e)}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                {" "}
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography className={classes.usdText}>
                    {getUsdSum()}{" "}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/*Arrow icon */}
          <Grid container justify="center">
            <ButtonBase disableRipple className={classes.arrowBtn}>
              <Icon icon="bi:arrow-down-circle" width="32" height="32" />
            </ButtonBase>
          </Grid>

          {/*INPUT wSFD FIELD  */}
          <Box className={classes.boxBackground}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                <Box display="flex" justifyContent="left" alignItems="center">
                  <WSfdIcon style={{ fontSize: 33, margin: 10 }} />
                  <Typography variant="h5" className="noselect">
                    wSFD
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  style={{
                    backgroundColor: "#F7F8FA",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <InputBase
                      value={wsfdAmount > 0 ? wsfdAmount : ""}
                      inputProps={{
                        style: {
                          fontSize: 30,
                          textAlign: "right",
                          marginRight: "10px",
                        },
                        readOnly: true,
                      }}
                      placeholder="0.0"
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/*BUTTON */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            size="large"
            disabled={!active || !account || loding || !sfdAmount}
          >
            {active && account ? (
              loding ? (
                <Grid container justify="center">
                  <Grid item>
                    <Typography>
                      {"Waiting for tx confirmation"}&nbsp; &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SfdCircularProgress size={20} isGrey />
                  </Grid>
                </Grid>
              ) : (
                "Confirm Mint"
              )
            ) : (
              "Wating for wallet connect.."
            )}
          </Button>
        </form>
      </Paper>
    </Grid>
  );
}

export default MintForm;
