import { Avatar, Tooltip } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ttIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#1a90ff;",
    "& :hover": {
      backgroundColor: "#64aff5",
    },
    
  },
}));

function IconTooltip(props) {
  const classes = useStyles();
  return (
    <Tooltip title={props.title} placement={props.pos}>
      <Avatar className={classes.ttIcon} >
        <Icon icon={props.icon} width={props.size} height={props.size} />
      </Avatar>
    </Tooltip>
  );
}

export default IconTooltip;
