import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export function SfdLinearProgress(props) {
  return <BorderLinearProgress variant="determinate" value={props.value} />;
}

//stupid but it works 200-white 300-grey
let circleBackground = 200; 
const useStylesFB = makeStyles((theme) => ({
  root: {
    position: "relative",
  },

  bottom: {
    color: theme.palette.grey[circleBackground],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));


export function SfdCircularProgress(props) {
  if (props.isGrey) circleBackground = 300;
  const classes = useStylesFB();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={props.size}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={props.size}
        thickness={4}
        {...props}
      />
    </div>
  );
}

