import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}


export function useContract(
    address: string | undefined,
    ABI: any,
    withSignerIfPossible = true
  ): Contract | null {
    const { library, account } = useWeb3React();
  
    return useMemo(() => {
      if (!address || !ABI || !library) return null;
      try {
        return getContract(
          address,
          ABI,
          library,
          withSignerIfPossible && account ? account : undefined
        );
      } catch (error) {
        console.error("Failed to get contract", error);
        return null;
      }
    }, [address, ABI, library, withSignerIfPossible, account]);
  }

  export default useContract;