import React from "react";
import SfdToolbar from "./ToolBar";
import SfdFooter from "./Footer";
import MintForm from "./MintForm";
import Minting from "./Minting";
import Burning from "./Burning";
import BurnForm from "./BurnForm";
import Faq from "./Faq";
import About from "./About";
import CoinListing from "./CoinListing";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "-1px",
    minHeight: "calc(100vh - 150px)",
    paddingTtop: "1px",
  },
}));

function MainPage() {
  const classes = useStyles();

  return (
    
    <Router>
      <SfdToolbar />
      <main className={classes.main}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/mint" />;
            }}
          />
          <Route path="/mint" exact component={() => <MintForm />} />
          <Route path="/minting" exact component={() => <Minting />} />
          <Route path="/burn" exact component={() => <BurnForm />} />
          <Route path="/burning" exact component={() => <Burning />} />
          <Route path="/faq" exact component={() => <Faq />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/listing" exact component={() => <CoinListing />} />
        </Switch>
      </main>
      <SfdFooter />
    </Router>
   
  );
}

export default MainPage;
