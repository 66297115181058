import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  Typography,
  Paper,
  InputBase,
  Box,
  ButtonBase,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { fromWei, toWei } from "web3-utils";
import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { useHistory } from "react-router-dom";

import useContract from "../hooks/useContract";
import sfdTokenAbi from "../constants/abis/sfdToken.json";
import sfdFactoryABI from "../constants/abis/sfdFactory.json";
import {
  sfdFactoryAddr,
  sfdTokenAddr,
  bridgeServURL
} from "../constants/consts";
import useInterval from "../hooks/useInterval";
import { escapeRegExp, inputNumberRegex } from "../utils/format";

import { BinanceChainIcon, SfdIcon, WSfdIcon } from "./icons/BridgeIcons";
import { Icon } from "@iconify/react";
import { SfdCircularProgress } from "./helpers/ProgressBars";

const useStyles = makeStyles((theme) => ({
  papperBack: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    flexGrow: 1,
    position: "relative",
    maxWidth: "500px",
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  cont: {
    alignItems: "center",
  },
  boxBackground: {
    backgroundColor: "#F7F8FA",
    borderColor: "#f4f6f8",
    "&:hover": {
      borderColor: "#e5e8ea",
    },
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.1),
  },

  boxBackgroundSelector: {
    backgroundColor: "#F7F8FA",
    borderColor: "#f4f6f8",
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "solid",
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.1),
  },

  usdText: {
    fontSize: "0.9em",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  balanceText: {
    fontSize: "0.9em",
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  maxBtn: {
    maxWidth: "35px",
    minWidth: "35px",
  },

  arrowBtn: {
    position: "absolute",
    margin: theme.spacing(-2.5),
    backgroundColor: "#F7F8FA",
    border: "0px",
    borderRadius: "15px",
    color: "#8b8e97",
    "& :hover": {
      color: "#afb2ba",
    },
  },

  burnBtn: {
    borderWidth: "1px",
    border: "solid",
    borderColor: "#1a90ff",
    borderRadius: "10px",
  },

  mintBtn: {
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: "#F7F8FA",
      color: "#1a90ff",
    },
  },

  selectorMargin: {
    margin: theme.spacing(0.2),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1a90ff",
    "&:hover": {
      backgroundColor: "#0d66ba",
    },
  },
}));

function BurnForm() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { account, active } = useWeb3React();
  const sfdFactory = useContract(sfdFactoryAddr, sfdFactoryABI, true);
  const sfdToken = useContract(sfdTokenAddr, sfdTokenAbi, true);

  const [sfdAmount, setSfdAmount] = useState(0);
  const [wsfdAmount, setWSfdAmount] = useState(0);
  const [wsfTextdAmount, setWsfTextdAmount] = useState("");
  const [usdSum, setUsdSum] = useState(0);
  const [sfdAddress, setSfdAddress] = useState("");
  const [approved, setApproved] = useState(false);
  const [loding, setLoding] = useState(false);
  const [wsfdBalance, setWsfdBalance] = useState<string>("0");
  const [errAddr, setErrAddr] = useState(false);
  const [sfdMultiplier, setSfdMultiplier] = useState(0.5);


  useEffect(() => {
    let isMounted = true;
    try {
      axios.get(`${bridgeServURL}/getMultiplier`).then(function (response) {
        let mul = response?.data?.sfdMultiplier;
        if (mul && isMounted)
          setSfdMultiplier(Number(mul));
      })
        .catch(function (error) {
          console.error(error);
        })
    } catch (e) {
      console.error(e);
    }
    return () => { isMounted = false };
  }, []);


  const checkApprove = useCallback(async () => {
    if (wsfdAmount <= 0 || !account) return;

    try {
      const allowance = await sfdToken?.allowance(account, sfdFactory?.address);
      if (
        BigNumber.from(allowance).gte(BigNumber.from(toWei(String(wsfdAmount))))
      ) {
        setApproved(true);
        return;
      }
      setApproved(false);
    } catch (e) {
      console.log("Err in checkApprove():", e);
    }
  }, [account, sfdFactory, sfdToken, wsfdAmount]);

  async function handleBurn(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!account || !sfdAddress || wsfdAmount <= 0) {
      enqueueSnackbar(`You need to fill in all the required fields`, {
        variant: "error",
      });
      return;
    }

    checkApprove();
    setLoding(true);

    if (!approved) {
      try {
        const apprvTx = await sfdToken?.approve(sfdFactoryAddr, MaxUint256);
        await apprvTx.wait(); // TODO: SET wating approve status
        setApproved(true);
      } catch (e) {
        console.log("Err in handleBurn() - (approve):", e);
      }
      setLoding(false);
      return;
    }
    let tx: any = null;
    let res: any = null;

    try {
      const wAmount = toWei(String(wsfdAmount));
      tx = await sfdFactory?.burn(wAmount, sfdAddress);
    } catch (error) {
      console.log("BURN Error:", error);
      enqueueSnackbar(
        `${error?.message
          ? error.message
          : "Can't use 'burn' method of smart contract 'sfdFactory'"
        }`,
        {
          variant: "error",
        }
      );
      setLoding(false);
      return;
    }

    try {
      res = await tx.wait();
    } catch (error) {
      console.log("BURN - tx.wait Error:", error);
      enqueueSnackbar(
        `${error?.message
          ? error.message
          : "Burn TX failed, some error in tx.wait"
        }`,
        {
          variant: "error",
        }
      );
      setLoding(false);
      return;
    }

    setLoding(false);

    if (res?.status !== 1) {
      enqueueSnackbar(`Burn TX failed: tx_status != 1`, {
        variant: "error",
      });
      console.log("BURN TX failed! tx_status != 1", res);
      return;
    }

    let burnEvent: any = null;
    for (let txEvent of res.events) {
      if (txEvent?.event === "Burned") {
        burnEvent = txEvent;
        break;
      }
    }

    if (!burnEvent) {
      enqueueSnackbar(`Can't find burn event (Burned) in transaction`, {
        variant: "error",
      });
      console.log("Cant find Event: 'Burned'", res);
      return;
    }

    console.log(burnEvent.args.requestHash);
    const handleTx = () =>
      history.push("/burning?hash=" + burnEvent.args.requestHash);
    handleTx();
  }

  useEffect(() => {
    checkApprove();
  }, [active, account, checkApprove]);

  async function updateWsfdBalance() {
    if (!active || !account) return;
    const balance = await sfdToken?.balanceOf(account);
    if (!balance) return;
    setWsfdBalance(fromWei(balance.toString()));
  }

  useEffect(() => {
    updateWsfdBalance();
  }, [active, account]);

  useInterval(updateWsfdBalance, 10000);

  function calcAmount(amount: number) {
    setSfdAmount(amount * sfdMultiplier);
    setWSfdAmount(amount);
  }

  function handleWSfdValueChange(inputStr: string) {
    if (inputNumberRegex.test(escapeRegExp(inputStr))) {
      setWsfTextdAmount(inputStr);
      const nAmount = Number(inputStr);
      calcAmount(nAmount ? nAmount : 0);
      return;
    }
    setWsfTextdAmount(wsfdAmount > 0 ? String(wsfdAmount) : "");
  }

  function handleSfdAddrChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const inputStr = event.target.value;
    if (inputStr[0] !== "S" || inputStr.length !== 34) {
      setErrAddr(true);
      return;
    }

    setErrAddr(false);
    setSfdAddress(inputStr);
  }

  async function calcUsdSum() {
    let price: number = 0;
    if (sfdAmount <= 0)
      return setUsdSum(0);
    try {
      let servResp = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=safe-deal"
      );
      price = servResp?.data["safe-deal"]?.usd;
      setUsdSum(price * sfdAmount);
    } catch (error) { }
  }

  useEffect(() => {
    calcUsdSum();
  }, [sfdAmount]);


  function getUsdSum() {
    return `~$ ${(Math.round(usdSum * 100) / 100).toFixed(2)}`;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
    

      <Paper className={classes.papperBack} elevation={5}>
        {/*MINT BURN SELECTOR*/}
        <Grid container justify="flex-end">
          <Grid item xs={6}>
            <Box className={classes.boxBackgroundSelector}>
              <Grid container className={classes.selectorMargin}>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    fullWidth
                    disableRipple
                    className={classes.mintBtn}
                    component={Link}
                    to="/mint"
                  >
                    MINT
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="large"
                    fullWidth
                    disableRipple
                    className={classes.burnBtn}
                  >
                    BURN
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <form className={classes.form} onSubmit={handleBurn} autoComplete="off">
          {/*INPUT wSFD FIELD  */}
          <Box className={classes.boxBackground}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                <Box display="flex" justifyContent="left" alignItems="center">
                  <WSfdIcon style={{ fontSize: 33, margin: 10 }} />
                  <Typography variant="h5" className="noselect">
                    wSFD
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  style={{
                    backgroundColor: "#F7F8FA",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <InputBase
                      inputProps={{
                        style: {
                          fontSize: 30,
                          textAlign: "right",
                          marginRight: "10px",
                        },
                      }}
                      placeholder="0.0"
                      onChange={(e) => handleWSfdValueChange(e.target.value)}
                      value={wsfTextdAmount}
                    />
                  </div>
                  {active ? (
                    <Button
                      variant="outlined"
                      size="small"
                      disableRipple
                      className={classes.maxBtn}
                      onClick={() => handleWSfdValueChange(wsfdBalance)}
                    >
                      Max
                    </Button>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                <Box display="flex" justifyContent="flex-start">
                  {active ? (
                    <Typography className={classes.balanceText}>
                      {`Balance: ${wsfdBalance} wSFD`}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography className={classes.usdText}>
                    {getUsdSum()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/*Arrow icon */}
          <Grid container justify="center">
            <ButtonBase disableRipple className={classes.arrowBtn}>
              <Icon icon="bi:arrow-down-circle" width="32" height="32" />
            </ButtonBase>
          </Grid>

          {/*INPUT SFD FIELD  */}

          <Box className={classes.boxBackground}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                <Box display="flex" justifyContent="left" alignItems="center">
                  <SfdIcon style={{ fontSize: 33, margin: 10 }} />
                  <Typography variant="h5" className="noselect">
                    SFD
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box
                  display="flex"
                  justifyContent="right"
                  alignItems="center"
                  style={{
                    backgroundColor: "#F7F8FA",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ marginLeft: "10px" }}>
                    <InputBase
                      inputProps={{
                        style: {
                          fontSize: 30,
                          textAlign: "right",
                          marginRight: "10px",
                        },
                        readOnly: true,
                      }}
                      placeholder="0.0"
                      onChange={(e) => handleWSfdValueChange(e.target.value)}
                      value={sfdAmount > 0 ? sfdAmount : ""}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <Grid item xs={4}>
                <Box display="flex" justifyContent="left" alignItems="center">
                  <SfdIcon style={{ fontSize: 33, margin: 10 }} />
                  <Typography variant="h5" className="noselect">
                    Address
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                {/*TODO fix ugly address input */}
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <div style={{ marginLeft: "10px" }}>
                    <InputBase
                      inputProps={{
                        style: {
                          fontSize: 14,
                          textAlign: "right",
                          marginRight: "10px",
                          minWidth: "280px",
                          maxWidth: "280px",
                        }
                      }}
                      placeholder="SVi2vm1XsWGpysrESW3eoniWjkSpMU5Fqf"
                      onChange={(e) => handleSfdAddrChange(e)}
                      error={errAddr}
                      fullWidth
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/*BUTTON */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            size="large"
            disabled={
              !active ||
              !account ||
              loding ||
              !sfdAddress ||
              !wsfdAmount ||
              errAddr
            }
          >
            <Grid container justify="center">
              <Grid item>
                <Typography>
                  {active && account
                    ? approved
                      ? "Confirm Burn"
                      : "Approve wSFD"
                    : "Wating for wallet connect.."}
                  &nbsp; &nbsp;
                </Typography>
              </Grid>
              <Grid item>
                {loding ? <SfdCircularProgress size={20} isGrey /> : null}
              </Grid>
            </Grid>
          </Button>
        </form>
      </Paper>
    </Grid>
  );
}

export default BurnForm;
