import React from "react";
import {
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  marginBox: {
    marginTop: theme.spacing(15),
  },
}));


function About() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={5}>
        <Box className={classes.marginBox}>
        <Typography variant="h4" paragraph={true} align="center" > <b>{`Welcome to the SafeDeal Bridge!`} </b> </Typography>
        <Typography variant="h5" paragraph={true}> {`The Bridge is the  important part of SafeDeal Project Ecosystem. 
        This is the unique way for classic  POW/POS/Masternode Coins based on Bitcoin/Dash/Pivx codebase get the direct road to the Ethereum-based blockains.`} <br />
       </Typography>
       <Typography variant="h5" paragraph={true}>
        {`At this moment the Bridge supports only BSC (Binance Smart Chain). The decisive factor in choosing BSC as the starting chain was its friendliness to users due to fairly low transaction fees.`} 
        </Typography>
        <Typography variant="h5" paragraph={true}>
        {`An important feature of the bridge is its scalability. During the development, the principles of unlimited growth were laid down, so our team is ready to implement any solutions`}
        </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default About;
