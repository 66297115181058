import React, { useState, useEffect } from "react"
import { Dialog, DialogContent, Typography, Button, makeStyles, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useWeb3React } from "@web3-react/core";
import { injected, bscConnector } from "../constants/connectors";
import { BinanceChainIcon, MetamaskFoxIcon } from "./icons/BridgeIcons";
import { SfdCircularProgress } from "./helpers/ProgressBars";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "7px 15px",
        position: 'relative',
        width: '250px',
        justifyContent: "flex-start",
        "& .MuiButton-endIcon": {
            margin: "5px 0px 4px auto"
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(0.5),
        top: theme.spacing(0.5)
    },
    dialogHeadBox: {
        borderBottom: "1px solid #cbd0d6",
        padding: "20px 20px 12px"
    },
    dialogContent: {
        paddingTop: "24px",
        paddingBottom: "5px"
    },
    walletBtn: {
        backgroundColor: "#1a90ff",
        "&:hover": {
          backgroundColor:"#0d66ba"
    }
}
}));

const WalletDialog = ({ isShowing, hide }: {
    isShowing: boolean,
    hide: () => void
}): JSX.Element => {
    const { account, activate, active, library, error } = useWeb3React();
    const classes = useStyles();
    const [loding, setLoding] = useState(false);

    const handleUnlockClick = (connector: AbstractConnector) => {
        setLoding(true);
        activate(connector).catch((error) => {
            setLoding(false);
            console.log("Error in activate", error);
        });
    }

    useEffect(() => {
        if (account && active) {
            setLoding(false);
            if (isShowing)
                hide();
            return;
        }
        if (error)
            setLoding(false);

    }, [account, active, library, error])

    return (
        <Dialog open={isShowing}
            onClose={hide}
        >
            <Box display="flex" justifyContent="center" className={classes.dialogHeadBox}>
                <Typography >
                    Select a Wallet
                </Typography>
                <Box display="flex" justifyContent="center">

                </Box>
                <IconButton size="small" aria-label="close" className={classes.closeButton} onClick={hide}>
                    <CloseIcon />
                </IconButton>

            </Box>
            <DialogContent className={classes.dialogContent}>
                <div>
                    <Button onClick={() => handleUnlockClick(injected)}
                        endIcon={<MetamaskFoxIcon />}
                        size="large"
                        fullWidth
                        variant="contained"
                        className={classes.walletBtn}
                        disabled={loding}
                        color="primary">
                        Metamask

                    </Button>
                </div>
                <div style={{ margin: "10px 0px 10px 0px" }}>
                    <Button onClick={() => handleUnlockClick(bscConnector)}
                        endIcon={<BinanceChainIcon />}
                        size="large"
                        fullWidth
                        variant="contained"
                        disabled={loding}
                        className={classes.walletBtn}
                        color="primary">
                        Binance Chain Wallet
                    </Button>
                </div>

                
                    <Box display="flex" justifyContent="center" style={{ margin: `10px 0px ${loding ? "0px" : "15px"} 0px` }}>
                    {loding ?<SfdCircularProgress size={45} />: null}
                    </Box>
                    

            </DialogContent>
        </Dialog>
    )
}

export default WalletDialog;