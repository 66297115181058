import React, { useState } from "react";
import {
  Toolbar,
  AppBar,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Link,
  Box,
  Tooltip
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import { makeStyles } from "@material-ui/core/styles";
import { useWeb3React } from "@web3-react/core";
import WalletDialog from "./WalletDialog";
import useWalletDialog from "../hooks/useWalletDialog";
import { shortenAddress } from "../utils/format";
import classNames from "classnames";
import { sfdTokenAddr } from "../constants/consts";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    homeButton: {
      marginRight: theme.spacing(1),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      flexGrow: 1,
    },
    toolbar: {
      boxShadow: "none",
    },
    addBtn: {
      marginRight: theme.spacing(2),
    },
  };
});

function SfdToolbar() {
  const classes = useStyles();
  const { isShowing, toggle } = useWalletDialog();
  const [anchorEl, setAnchorEl] = useState(null);
  const { account, deactivate, active, library } = useWeb3React();

  function onClickConnectWallet() {
    if (!active) toggle();
  }

  const handelOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function AddwSFDToMetaMask() {
    const params: any = {
      type: "ERC20",
      options: {
        address:
          sfdTokenAddr,
        symbol: "wSFD",
        decimals: 18,
        image:
          "https://safedeal.trade/files/2020/05/LOGO_85.png",
      },
    };

    if (
      library &&
      library.provider.isMetaMask &&
      library.provider.request
    ) {
      library.provider
        .request({
          method: "wallet_watchAsset",
          params,
        })
        .then((success) => {
          if (success) {
            console.log(
              "Successfully added wSFD to MetaMask"
            );
          } else {
            throw new Error(
              "Something went wrong."
            );
          }
        })
        .catch(console.error);
    }
  }
  return (
    <AppBar position="static" color="transparent" className={classes.toolbar}>
      <Box>
        <Toolbar>
          <Link href={"/"} color="inherit">
            <IconButton
              aria-label="Home"
              color="inherit"
              className={classes.homeButton}
            >
              <HomeIcon />
            </IconButton>
          </Link>

          <Typography variant="h6" className={classNames(classes.logo, "noselect")}>
            SafeDeal Bridge
          </Typography>

          <IconButton
            aria-label="Menu"
            color="inherit"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {library && library.provider.isMetaMask ? (
            <Tooltip title="Add wSFD to your MetaMask wallet">
              <Button
                variant="outlined"
                color="secondary"
                className={classes.addBtn}
                onClick={() => AddwSFDToMetaMask()}
              >
                wSFD
              </Button>
            </Tooltip>
          ) : null}

          <Button
            variant="outlined"
            color="secondary"
            onClick={(e) => {
              account && active ? handelOpenMenu(e) : onClickConnectWallet();
            }}
          >
            {account && active
              ? shortenAddress(String(account))
              : "Connect Wallet"}
          </Button>

          <Menu
            id="address-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                deactivate();
                handleMenuClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Box>
      <WalletDialog isShowing={isShowing} hide={toggle} />
    </AppBar>
  );
}

export default SfdToolbar;
