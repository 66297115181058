import {
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useState } from "react";
import { Icon } from "@iconify/react";

export function InfoButton(props) {
  return (
      <Tooltip title={props.helpText}>
       <IconButton aria-label="Info" color="default" size="small" disableRipple>
        <Icon icon="ant-design:info-circle-twotone" width="12" height="12" />
      </IconButton>
    </Tooltip>
  );
}


export function CopyButton(props) {
  const [copyTooltipText,setCopyTooltopText] = useState('Click to copy');
  return (
   // <Tooltip title={copyTooltipText} onOpen={(e)=> setCopyTooltopText('Click to copy')}>
   <Tooltip title={copyTooltipText}>
    <IconButton
      aria-label="Copy"
      color="default"
      size="small"
      disableRipple
      onClick={(e) => {
        navigator.clipboard.writeText(props.textToCopy);
        setCopyTooltopText('Copied to buffer');
        setTimeout(()=>setCopyTooltopText('Click to copy'),2000)
      
      }}
    >
      <Icon icon="ant-design:copy-twotone" width="12" height="12" />
    </IconButton>
    </Tooltip>
  );
}

