import { useEffect } from "react";
import { bscConnector } from "../constants/connectors"
import { useWeb3React } from "@web3-react/core";
import { AbstractConnector } from '@web3-react/abstract-connector';
import { BSC_KEY } from "../constants/consts";

const _binanceChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc
      },
      set(bsc) {
        this.bsc = bsc
        resolve()
      },
    }),
  )

const BscLogin = (activate: (connector: AbstractConnector, onError?: (error: Error) => void, throwErrors?: boolean) => Promise<void>, active: boolean) => {
  if (active)
    return;
  activate(bscConnector, undefined, true).catch((err) => {
    console.log("Error in activation bscConnector");
  })

}

function useBscConnect() {
  const { activate, active } = useWeb3React()
  const isBinanceChainDefined = Reflect.has(window, 'BinanceChain')

  useEffect(() => {
    const bscWAuth = window.localStorage.getItem(BSC_KEY)
    if (bscWAuth === "BSC") {
      if (!isBinanceChainDefined) {
        _binanceChainListener().then(() => BscLogin(activate, active))
        return;
      }
      BscLogin(activate, active);
    }
  }, [])

}

export default useBscConnect;
