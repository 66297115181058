import React, { useEffect } from "react";
import { useSnackbar } from 'notistack';
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import useEagerConnect from "../hooks/useEagerConnect";
import useInactiveListener from "../hooks/useInactiveListener";
import useBscConnect from "../hooks/useBscConnect";
import { bscConnector } from "../constants/connectors"

import { BSC_KEY } from "../constants/consts";


export default function Web3ReactManager({
  children,
}: {
  children: JSX.Element;
}) {
  const { active, error, connector } = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();
  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  useBscConnect();
  useEffect(() => {
    if (!active && error) {
      window.localStorage.removeItem(BSC_KEY);

      let errMessage = "";

      if(error instanceof UnsupportedChainIdError)
        errMessage="You are on the wrong network";

      errMessage = error?.message ? error.message : String(error);

      enqueueSnackbar(`Wallet connecting error: ${errMessage ? errMessage : 'unknown error occurred'}`, {
        variant: 'error',
        preventDuplicate: true
      })
    }
    else {
      if (connector === bscConnector && window.localStorage.getItem(BSC_KEY) !== "BSC") {
        window.localStorage.setItem(BSC_KEY, "BSC");
        console.log('set BSC localStorage');
      }
    }

  }, [error, active, connector])
  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error

  return children;
}
