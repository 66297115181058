import React from "react";
import {
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  marginBox: {
    marginTop: theme.spacing(15),
  },
}));


const iframe = '<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScj8AJk7_OCZmKS96Xjya1vmR3IUt33YGVMXRL3g1KVwcCPCg/viewform?embedded=true" width="640" height="641" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>';
function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}


function CoinListing() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={5}>
        <Box className={classes.marginBox}>
        <Typography variant="h4" paragraph={true} align="center" > <b>{`Coin Listing`} </b> </Typography>
        <Typography variant="h5" paragraph={true}> {`This section is intended for developers of coins based on Bitcoin/Dash/Pivx codebase. If you want to open the road to the BSC chain and tokenize your coin - you are in right place. 
        Tokenizing opens wide perspectives for your coin, the best of them are entering to DeFi sector and DEX exchanges. `} <br />
       </Typography>
       <Typography variant="h5" paragraph={true}>
       {`Listing on the SafeDeal Bridge will give your project easy way to convert coins from your blockchain to BSC blockchain and back.`}
       </Typography>
       <Typography variant="h5" paragraph={true}>
       {`At the moment, we are studying the primary interest in this service. We are ready for full cooperation and accept any proposals and suggestions.`}
       </Typography>
       <Typography variant="h5" paragraph={true}>
       {`If you interesting in this Service, please fill the form below to continue cooperation.`}
       </Typography>
       <Box display="flex" justifyContent="right" alignItems="center">
       <Iframe iframe={iframe} />,
       </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CoinListing;
