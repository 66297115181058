import React from "react";
import {
  Grid,
  Box, Link
} from "@material-ui/core";
import Faq from "react-faq-component";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  marginBox: {
    marginTop: theme.spacing(3),
  },
}));

const faqStyles = {
    bgColor: '#fafafa',
    titleTextColor: "black",
    rowTitleColor: "#1a90ff",
    titleTextSize: '28px',
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };
  
  const faqConfig = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };
  



const data_Common = {
  title: "Common",
  rows: [
    {
      title: "What is SFD Bridge",
      content: `The Bridge is an online service built to transfer SFD coins to wSFD tokens and back
      This is the easiest way to move SFD between the main SFD Blockchain and other chains.`,
    },
    {
      title: "Who is behind this project",
      content:
        `The Bridge is a part of global the SafeDeal Project Ecosystem. SafeDeal Team started its public history on 8 august 2020. 
        The team adheres to the principles of free Internet and anonymity, but to increase the trust of the community we have passed
        <a href="https://media.discordapp.net/attachments/742864608108216423/801792505938051082/listing_KYC.jpg" target="_blank">Identity Verification for developers</a>  
        on Crex24.com exchange. To check it - go to <a href="https://crex24.com/exchange/SFD-BTC" target="_blank">Crex24.com</a>, select tab Coin Info and point mouse to Green Checkmark.`,
    },
    {
      title: "Can i trust the project",
      content: `All Bridge  functional is fully transparent because it's working on Smart Contracts. 
      Every time when you are Minting or Burning - Bridge starts interaction with the functions of the smart contract. 
      Each operation can be checked in <a href="https://bscscan.com/address/0xDE524CE067C5268b1638B5802c98f5919664838d" target="_blank"> the official BSC explorer</a>.`,
    },
    {
      title: "Where can i get help",
      content: `You can ask your question in our <a href="https://discord.safedeal.trade/" target="_blank">Dsicord</a>, 
      <a href="https://t.me/safedealp2p" target="_blank">Telegram</a> or use our <a href="https://safedeal.freshdesk.com/support/tickets/new">Ticket system</a>.`,
    },
  ],
}; 

const data_Sfd = {
  title: "SFD Coin",
  rows: [
    {
      title: "What is SFD Coin ",
      content: `This is POS+Masternode coin with its own Blockchain. Max Supply is 10 mln and it will be reached approx in 2027.`,
    },
    {
      title: "What is SFD Blockchain",
      content:
        "This is the main network of SFD Coin. Only here new SFD coins are producing as Masternode & POS rewards.",
    },
    {
      title: "Where to get a SFD wallet",
      content: `You can download wallets from an official github repository <a href="https://github.com/safedeal-project/safedeal/releases" target="_blank">GITHUB</a> `,
    },
    {
      title: "How to get a SFD Coin",
      content: `- Buy SFD on exchange: <a href="https://crex24.com/exchange/SFD-BTC" target="_blank">Crex24.com</a>, <a href="https://graviex.net/markets/sfdbtc" target="_blank">Graviex.net</a>, 
      <a href="https://main.southxchange.com/Market/Book/SFD/BTC" target="_blank">Southxchange.com</a>, <a href="https://www.finexbox.com/market/pair/SFD-BTC.html" target="_blank">Finexbox.com</a> <br>
      - Build Masternode and receive daily rewards. Check stats on <a href="https://masternodes.online/currencies/SFD/" target="_blank">Masternodes.online</a> <br>
      - Keep coins in a wallet and take part in Staking `,
    },
  ],
};

const data_wSfd = {
  title: "wSFD Token",
  rows: [
    {
      title: "What is wSFD Token",
      content: `Wrapped SFD Coin (wSFD) is a BEP20 token that represents SafeDeal Coin (SFD) on the Binance blockchain (BSC).`,
    },
    {
      title: "What is BSC Blockchain",
      content:
        `Binance Smart Chain (BSC) is an Ethereum-compatible blockchain that offers the same smart contract capabilities at much cheaper transaction fees.
        Read more on  <a href="https://academy.binance.com/en/articles/an-introduction-to-binance-smart-chain-bsc" target="_blank">academy.binance.com</a>`,
    },
    {
      title: "Where to get a wSFD wallet",
      content: `We recommended to use <a href="https://metamask.io/download.html">Metamask</a>. 
      Read <a href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank">this article</a> if you never used Metamask with BSC.`,
    },
    {
      title: "How to add wSFD to  wallet",
      content: `We can do it for you automatically. Connect your Wallet and simply click on wSFD button on the toolbar.`,
    },
    {
        title: "How to get a wSFD Token",
        content: `For now, wSFD token can be only received by swapping from a SFD coin with the help of this bridge. Soon DEX exchanges will be available.`,
    },
  ],
};

const data_Bridge = {
  title: "SFD Bridge",
  rows: [
    {
      title: "What is Minting",
      content: `Minting is the process of creating new wSFD Tokens by utilizing your SFD Coins. You will receive wSFD Tokens, while  your SFD Coins will be removed from circulation.`,
    },
    {
      title: "What is Burning",
      content:
        "Burning is the process of creating new SFD Coins by utilizing your wSFD Tokens. You will receive SFD Coins, while  your wSFD Tokens will be removed from circulation.",
    },
    {
      title: "What is Order id",
      content: `Order ID is your unique exchange id in SFD Bridge. Save it and you can always return to the unfinished exchange. It is also needed to provide technical support. `,
    },
    {
      title: "Can i send several tx while Minting",
      content: `Yes you can send as many transactions as you need. The Bridge  will wait until the requested number of SFD Сoins has been deposited.`,
    },
    {
      title: "Can i send several tx while Burning",
      content: `No, you can't. The exact amount of wSFD will be debited at a time from your Wallet to the Bridge.`,
    },
  ],
};


function FAQ() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={5}>
        <Box className={classes.marginBox}>
          <Faq data={data_Common} styles={faqStyles} config={faqConfig} />
        </Box>
        <Box className={classes.marginBox}>
          <Faq data={data_Sfd} styles={faqStyles} config={faqConfig} />
        </Box>
        <Box className={classes.marginBox}>
          <Faq data={data_wSfd} styles={faqStyles} config={faqConfig} />
        </Box>
        <Box className={classes.marginBox}>
          <Faq data={data_Bridge} styles={faqStyles} config={faqConfig} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default FAQ;
